import ChangeCartSolution from "~/components/partials/cart/solutions/ChangeCartSolution.vue";
import ChangeShipmentMethodSolution from "~/components/partials/cart/solutions/ChangeShipmentMethodSolution.vue";
import DeliveryMethodSolution from "~/components/partials/cart/solutions/DeliveryMethodSolution.vue";
import type { ShippingMethodType } from "~/types/kippie";

export const DEPOSIT_PRODUCT_IDS = ["018adae7cde0707c98956d41fc598a14", "018adae7cde17213af41adb77723726a"];

export const CART_ERROR_MESSAGES: Record<string, { message: string; solution?: InstanceType<any> }> = {
	"kwb-deposit-not-available-line-item-blocked": {
		message: "Borg Kippiepan is niet beschikbaar zonder Kippiepan.",
		solution: ChangeCartSolution
	},
	"kwb-deposit-to-many-deposit-items-blocked": {
		message: "Aantal Borg Kippiepannen is niet beschikbaar voor deze bestelling.",
		solution: ChangeCartSolution
	},
	"kwb-shipping-method-not-available": {
		message: "De gekozen verzendmethode is niet mogelijk voor sommige producten in je winkelmandje.",
		solution: ChangeShipmentMethodSolution
	},
	"shipping-method-blocked-Bezorgen": {
		message: "",
		solution: DeliveryMethodSolution
	},
	"kwb-product-out-of-stock-error": {
		message: "Sommige producten zijn niet meer verkrijgbaar in de door jouw gekozen winkel."
	}
};

export const SHIPPING_METHODS: Record<
	ShippingMethodType,
	{ shopwareId: string; name: string; type: ShippingMethodType }
> = {
	pickup: {
		shopwareId: "018a9309f14b700297a1002cd8616773",
		name: "Afhalen",
		type: "pickup" as ShippingMethodType
	},
	delivery: {
		shopwareId: "018a9309f14b700297a1002cd96162fe",
		name: "Bezorgen",
		type: "delivery" as ShippingMethodType
	}
};

export const SHIPPING_METHODS_LIST = Object.values(SHIPPING_METHODS);

export const IDEAL_BANKS = [
	{
		name: "ABN AMRO",
		code: "ABNANL2A"
	},
	{
		name: "ASN Bank",
		code: "ASNBNL21"
	},
	{
		name: "Bunq Bank",
		code: "BUNQNL2A"
	},
	{
		name: "ING",
		code: "INGBNL2A"
	},
	{
		name: "Knab Bank",
		code: "KNABNL2H"
	},
	{
		name: "Rabobank",
		code: "RABONL2U"
	},
	{
		name: "RegioBank",
		code: "RBRBNL21"
	},
	{
		name: "SNS Bank",
		code: "SNSBNL2A"
	},
	{
		name: "Triodos Bank",
		code: "TRIONL2U"
	},
	{
		name: "Van Lanschot",
		code: "FVLBNL22"
	},
	{
		name: "Handelsbanken",
		code: "HANDNL2A"
	},
	{
		name: "Revolut",
		code: "REVOLT21"
	}
];
