<script setup lang="ts">
type TypographyVariant = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";

withDefaults(
	defineProps<{
		variant: TypographyVariant;
	}>(),
	{
		variant: "span"
	}
);

const classMap: Record<TypographyVariant, string> = {
	h1: "font-brush text-4xl leading-none py-0",
	h2: "font-brush text-3xl leading-none py-0",
	h3: "font-brush text-2xl leading-none py-0",
	h4: "font-brush text-xl leading-none py-0",
	h5: "font-brush text-lg leading-none py-0",
	h6: "font-sans font-bold text-base leading-none py-0",
	p: "text-sm",
	span: "text-sm"
};
</script>

<template>
	<component :is="variant" :class="classMap[variant]"><slot /></component>
</template>
