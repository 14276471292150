import { createInstance } from "@shopware-pwa/api-client";

export const useShopwareApi = (contextToken?: string) => {
	const { apiInstance } = useShopwareContext();
	const { searchParams } = useRequestURL();

	if (searchParams.get("context") || contextToken) {
		const api = createInstance({
			...apiInstance.config,
			contextToken: searchParams.get("context") || contextToken
		});

		return api;
	}

	return apiInstance;
};
