<script setup lang="ts">
import * as pressable from "@zag-js/pressable";
import { normalizeProps, useMachine } from "@zag-js/vue";
import { computed } from "vue";
import { Icon, SvgIcon } from "../";

defineEmits(["close"]);

const props = withDefaults(
	defineProps<{
		disabled?: boolean;
		icon?: Icon;
		badge?: number;
		label?: string;
		fake?: boolean;
	}>(),
	{
		disabled: false,
		icon: "close",
		badge: undefined,
		label: "Close"
	}
);

const [state, send] = useMachine(
	pressable.machine({
		id: (Math.random() + 1).toString(36).substring(7),
		disabled: props.disabled
	})
);

const api = computed(() => pressable.connect(state.value, send, normalizeProps));

const element = props.fake ? "div" : "button";
</script>

<template>
	<component
		:is="element"
		v-bind="{ ...api.pressableProps, disabled: $props.disabled }"
		class="w-10 h-10 flex justify-center items-center relative disabled:opacity-70 disabled:cursor-not-allowed outline-none hover:scale-105 duration-200 ease-in-out"
		:disabled="disabled"
		:class="{
			'translate-y-[2px]': api.isPressed && !disabled
		}"
		:aria-label="label"
		@close="$emit('close')"
	>
		<div class="relative">
			<SvgIcon name="egg" class="text-white w-full h-full" />
			<div
				class="w-[33px] h-8 absolute bg-brown -z-[1] rounded-full left-[2px] -bottom-px transition-opacity opacity-0 group-hover:opacity-100"
			/>
		</div>
		<SvgIcon :name="icon" class="w-6 h-6 absolute" />
		<div v-if="badge" class="absolute -right-1 top-0 overflow-hidden text-2xs font-bold">
			<SvgIcon name="egg" class="text-yellow w-5 h-5" />
			<p class="py-[2px] absolute top-0 left-1/2 -translate-x-1/2">
				{{ badge || 1 }}
			</p>
		</div>
	</component>
</template>
