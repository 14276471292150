import { default as _91_46_46_46slug_930NssoJ9SMzMeta } from "/vercel/path0/apps/storefront/src/pages/[...slug].vue?macro=true";
import { default as bedanktDqk5g5k5NCMeta } from "/vercel/path0/apps/storefront/src/pages/account/bedankt.vue?macro=true";
import { default as bestellingenA1KFeOlTtCMeta } from "/vercel/path0/apps/storefront/src/pages/account/bestellingen.vue?macro=true";
import { default as indexkG61FKwgdqMeta } from "/vercel/path0/apps/storefront/src/pages/account/index.vue?macro=true";
import { default as inloggencrIGAY2syqMeta } from "/vercel/path0/apps/storefront/src/pages/account/inloggen.vue?macro=true";
import { default as wachtwoord_45resettenNevIIn8oxVMeta } from "/vercel/path0/apps/storefront/src/pages/account/wachtwoord-resetten.vue?macro=true";
import { default as wachtwoord_45vergetenlZXRjBSeDiMeta } from "/vercel/path0/apps/storefront/src/pages/account/wachtwoord-vergeten.vue?macro=true";
import { default as _91_46_46_46all_93bNEpDY25SMMeta } from "/vercel/path0/apps/storefront/src/pages/assortiment/[...all].vue?macro=true";
import { default as _91orderId_935bzTCCX8G5Meta } from "/vercel/path0/apps/storefront/src/pages/bestellen/bedankt/[orderId].vue?macro=true";
import { default as gegevensHB4s54fmUmMeta } from "/vercel/path0/apps/storefront/src/pages/bestellen/gegevens.vue?macro=true";
import { default as inloggenP5TwsiFx9AMeta } from "/vercel/path0/apps/storefront/src/pages/bestellen/inloggen.vue?macro=true";
import { default as leveringVO30bFD7JAMeta } from "/vercel/path0/apps/storefront/src/pages/bestellen/levering.vue?macro=true";
import { default as levertijdjeEvXvwmQ7Meta } from "/vercel/path0/apps/storefront/src/pages/bestellen/levertijd.vue?macro=true";
import { default as overzicht4LflZjASNoMeta } from "/vercel/path0/apps/storefront/src/pages/bestellen/overzicht.vue?macro=true";
import { default as registrerenTK7BDC34uaMeta } from "/vercel/path0/apps/storefront/src/pages/bestellen/registreren.vue?macro=true";
import { default as contactC9DNgJkeBiMeta } from "/vercel/path0/apps/storefront/src/pages/contact.vue?macro=true";
import { default as _91id_930eMhefCJcUMeta } from "/vercel/path0/apps/storefront/src/pages/detail/[id].vue?macro=true";
import { default as _91slug_93BVARLhus2AMeta } from "/vercel/path0/apps/storefront/src/pages/product/[slug].vue?macro=true";
import { default as _91name_93ecl0QmqgBKMeta } from "/vercel/path0/apps/storefront/src/pages/winkels/[name].vue?macro=true";
import { default as _91query_93GM7PX4bg6TMeta } from "/vercel/path0/apps/storefront/src/pages/zoeken/[query].vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_930NssoJ9SMzMeta?.name ?? "slug",
    path: _91_46_46_46slug_930NssoJ9SMzMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_930NssoJ9SMzMeta || {},
    alias: _91_46_46_46slug_930NssoJ9SMzMeta?.alias || [],
    redirect: _91_46_46_46slug_930NssoJ9SMzMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/storefront/src/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: bedanktDqk5g5k5NCMeta?.name ?? "account-bedankt",
    path: bedanktDqk5g5k5NCMeta?.path ?? "/account/bedankt",
    meta: bedanktDqk5g5k5NCMeta || {},
    alias: bedanktDqk5g5k5NCMeta?.alias || [],
    redirect: bedanktDqk5g5k5NCMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/storefront/src/pages/account/bedankt.vue").then(m => m.default || m)
  },
  {
    name: bestellingenA1KFeOlTtCMeta?.name ?? "account-bestellingen",
    path: bestellingenA1KFeOlTtCMeta?.path ?? "/account/bestellingen",
    meta: bestellingenA1KFeOlTtCMeta || {},
    alias: bestellingenA1KFeOlTtCMeta?.alias || [],
    redirect: bestellingenA1KFeOlTtCMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/storefront/src/pages/account/bestellingen.vue").then(m => m.default || m)
  },
  {
    name: indexkG61FKwgdqMeta?.name ?? "account",
    path: indexkG61FKwgdqMeta?.path ?? "/account",
    meta: indexkG61FKwgdqMeta || {},
    alias: indexkG61FKwgdqMeta?.alias || [],
    redirect: indexkG61FKwgdqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/storefront/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: inloggencrIGAY2syqMeta?.name ?? "account-inloggen",
    path: inloggencrIGAY2syqMeta?.path ?? "/account/inloggen",
    meta: inloggencrIGAY2syqMeta || {},
    alias: inloggencrIGAY2syqMeta?.alias || [],
    redirect: inloggencrIGAY2syqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/storefront/src/pages/account/inloggen.vue").then(m => m.default || m)
  },
  {
    name: wachtwoord_45resettenNevIIn8oxVMeta?.name ?? "account-wachtwoord-resetten",
    path: wachtwoord_45resettenNevIIn8oxVMeta?.path ?? "/account/wachtwoord-resetten",
    meta: wachtwoord_45resettenNevIIn8oxVMeta || {},
    alias: wachtwoord_45resettenNevIIn8oxVMeta?.alias || [],
    redirect: wachtwoord_45resettenNevIIn8oxVMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/storefront/src/pages/account/wachtwoord-resetten.vue").then(m => m.default || m)
  },
  {
    name: wachtwoord_45vergetenlZXRjBSeDiMeta?.name ?? "account-wachtwoord-vergeten",
    path: wachtwoord_45vergetenlZXRjBSeDiMeta?.path ?? "/account/wachtwoord-vergeten",
    meta: wachtwoord_45vergetenlZXRjBSeDiMeta || {},
    alias: wachtwoord_45vergetenlZXRjBSeDiMeta?.alias || [],
    redirect: wachtwoord_45vergetenlZXRjBSeDiMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/storefront/src/pages/account/wachtwoord-vergeten.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93bNEpDY25SMMeta?.name ?? "assortiment-all",
    path: _91_46_46_46all_93bNEpDY25SMMeta?.path ?? "/assortiment/:all(.*)*",
    meta: _91_46_46_46all_93bNEpDY25SMMeta || {},
    alias: _91_46_46_46all_93bNEpDY25SMMeta?.alias || [],
    redirect: _91_46_46_46all_93bNEpDY25SMMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/storefront/src/pages/assortiment/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91orderId_935bzTCCX8G5Meta?.name ?? "bestellen-bedankt-orderId",
    path: _91orderId_935bzTCCX8G5Meta?.path ?? "/bestellen/bedankt/:orderId()",
    meta: _91orderId_935bzTCCX8G5Meta || {},
    alias: _91orderId_935bzTCCX8G5Meta?.alias || [],
    redirect: _91orderId_935bzTCCX8G5Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/storefront/src/pages/bestellen/bedankt/[orderId].vue").then(m => m.default || m)
  },
  {
    name: gegevensHB4s54fmUmMeta?.name ?? "bestellen-gegevens",
    path: gegevensHB4s54fmUmMeta?.path ?? "/bestellen/gegevens",
    meta: gegevensHB4s54fmUmMeta || {},
    alias: gegevensHB4s54fmUmMeta?.alias || [],
    redirect: gegevensHB4s54fmUmMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/storefront/src/pages/bestellen/gegevens.vue").then(m => m.default || m)
  },
  {
    name: inloggenP5TwsiFx9AMeta?.name ?? "bestellen-inloggen",
    path: inloggenP5TwsiFx9AMeta?.path ?? "/bestellen/inloggen",
    meta: inloggenP5TwsiFx9AMeta || {},
    alias: inloggenP5TwsiFx9AMeta?.alias || [],
    redirect: inloggenP5TwsiFx9AMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/storefront/src/pages/bestellen/inloggen.vue").then(m => m.default || m)
  },
  {
    name: leveringVO30bFD7JAMeta?.name ?? "bestellen-levering",
    path: leveringVO30bFD7JAMeta?.path ?? "/bestellen/levering",
    meta: leveringVO30bFD7JAMeta || {},
    alias: leveringVO30bFD7JAMeta?.alias || [],
    redirect: leveringVO30bFD7JAMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/storefront/src/pages/bestellen/levering.vue").then(m => m.default || m)
  },
  {
    name: levertijdjeEvXvwmQ7Meta?.name ?? "bestellen-levertijd",
    path: levertijdjeEvXvwmQ7Meta?.path ?? "/bestellen/levertijd",
    meta: levertijdjeEvXvwmQ7Meta || {},
    alias: levertijdjeEvXvwmQ7Meta?.alias || [],
    redirect: levertijdjeEvXvwmQ7Meta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/storefront/src/pages/bestellen/levertijd.vue").then(m => m.default || m)
  },
  {
    name: overzicht4LflZjASNoMeta?.name ?? "bestellen-overzicht",
    path: overzicht4LflZjASNoMeta?.path ?? "/bestellen/overzicht",
    meta: overzicht4LflZjASNoMeta || {},
    alias: overzicht4LflZjASNoMeta?.alias || [],
    redirect: overzicht4LflZjASNoMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/storefront/src/pages/bestellen/overzicht.vue").then(m => m.default || m)
  },
  {
    name: registrerenTK7BDC34uaMeta?.name ?? "bestellen-registreren",
    path: registrerenTK7BDC34uaMeta?.path ?? "/bestellen/registreren",
    meta: registrerenTK7BDC34uaMeta || {},
    alias: registrerenTK7BDC34uaMeta?.alias || [],
    redirect: registrerenTK7BDC34uaMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/storefront/src/pages/bestellen/registreren.vue").then(m => m.default || m)
  },
  {
    name: contactC9DNgJkeBiMeta?.name ?? "contact",
    path: contactC9DNgJkeBiMeta?.path ?? "/contact",
    meta: contactC9DNgJkeBiMeta || {},
    alias: contactC9DNgJkeBiMeta?.alias || [],
    redirect: contactC9DNgJkeBiMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/storefront/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _91id_930eMhefCJcUMeta?.name ?? "detail-id",
    path: _91id_930eMhefCJcUMeta?.path ?? "/detail/:id()",
    meta: _91id_930eMhefCJcUMeta || {},
    alias: _91id_930eMhefCJcUMeta?.alias || [],
    redirect: _91id_930eMhefCJcUMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/storefront/src/pages/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BVARLhus2AMeta?.name ?? "product-slug",
    path: _91slug_93BVARLhus2AMeta?.path ?? "/product/:slug()",
    meta: _91slug_93BVARLhus2AMeta || {},
    alias: _91slug_93BVARLhus2AMeta?.alias || [],
    redirect: _91slug_93BVARLhus2AMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/storefront/src/pages/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91name_93ecl0QmqgBKMeta?.name ?? "winkels-name",
    path: _91name_93ecl0QmqgBKMeta?.path ?? "/winkels/:name()",
    meta: _91name_93ecl0QmqgBKMeta || {},
    alias: _91name_93ecl0QmqgBKMeta?.alias || [],
    redirect: _91name_93ecl0QmqgBKMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/storefront/src/pages/winkels/[name].vue").then(m => m.default || m)
  },
  {
    name: _91query_93GM7PX4bg6TMeta?.name ?? "zoeken-query",
    path: _91query_93GM7PX4bg6TMeta?.path ?? "/zoeken/:query()",
    meta: _91query_93GM7PX4bg6TMeta || {},
    alias: _91query_93GM7PX4bg6TMeta?.alias || [],
    redirect: _91query_93GM7PX4bg6TMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/storefront/src/pages/zoeken/[query].vue").then(m => m.default || m)
  }
]