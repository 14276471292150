import {
	login as apiLogin,
	register as apiRegister,
	logout as apiLogout,
	getCustomer,
	updateProfile,
	updateEmail as apiUpdateEmail,
	setDefaultCustomerPaymentMethod
} from "@shopware-pwa/api-client";
import type { ShopwareSearchParams } from "@shopware-pwa/types";
import type { CustomerRegistrationParams } from "@shopware-pwa/types";

const useShopwareUserImplementation = () => {
	const apiInstance = useShopwareApi();

	const { userFromContext, refreshSessionContext, isLoading } = useShopwareSession();
	const user = computed({
		get: () => userFromContext.value,
		set: (user) => (userFromContext.value = user)
	});

	const { refreshCart } = useShopwareCart();

	async function login(credentials: { username: string; password: string }) {
		await apiLogin(credentials, apiInstance);
		await refreshSessionContext();
		refreshCart();
	}

	async function register(params: CustomerRegistrationParams) {
		const customer = await apiRegister(
			{ ...params, storefrontUrl: window.location.origin } as CustomerRegistrationParams,
			apiInstance
		);
		user.value = customer;
		if (user.value?.active) await refreshSessionContext();
		return customer;
	}

	async function logout() {
		await apiLogout(apiInstance);
		await refreshSessionContext();
		refreshCart();
	}

	async function refreshUser(params: ShopwareSearchParams = {}) {
		try {
			user.value = (await getCustomer(params, apiInstance)) || undefined;
		} catch (e) {
			console.error("[useUser][refreshUser]", e);
		}
	}

	async function updatePersonalInfo(personals: {
		firstName: string;
		lastName: string;
		salutationId: string;
		title: string | null;
	}) {
		await updateProfile(personals, apiInstance);
	}

	async function updateEmail(updateEmailData: { email: string; emailConfirmation: string; password: string }) {
		await apiUpdateEmail(updateEmailData, apiInstance);
	}

	async function setDefaultPaymentMethod(paymentMethodId: string) {
		await setDefaultCustomerPaymentMethod(paymentMethodId, apiInstance);
	}

	const userDefaultPaymentMethod = computed(() => user.value?.defaultPaymentMethod?.translated);
	const userDefaultBillingAddress = computed(() => user.value?.defaultBillingAddress);
	const userDefaultShippingAddress = computed(() => user.value?.defaultShippingAddress);
	const defaultBillingAddressId = computed(() => user.value?.defaultBillingAddressId);
	const defaultShippingAddressId = computed(() => user.value?.defaultShippingAddressId);
	const isLoggedIn = computed(() => !!user.value?.id && !!user.value.active && !user.value.guest);
	const isCustomerSession = computed(() => !!user.value?.id && !user.value.guest);
	const isGuestSession = computed(() => !!user.value?.guest);

	return {
		login,
		register,
		isLoading,
		user,
		isLoggedIn,
		isCustomerSession,
		isGuestSession,
		refreshUser,
		logout,
		updateEmail,
		updatePersonalInfo,
		setDefaultPaymentMethod,
		defaultBillingAddressId,
		defaultShippingAddressId,
		userDefaultPaymentMethod,
		userDefaultBillingAddress,
		userDefaultShippingAddress
	};
};

export const useShopwareUser = createSharedComposable(useShopwareUserImplementation);
