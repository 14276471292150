import revive_payload_client_yKCoKkRoyU from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_@unocss+reset@0.59.2_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4.5_unocss@0.59.2_vite@4.5.3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Q7Pk59Js85 from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_@unocss+reset@0.59.2_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4.5_unocss@0.59.2_vite@4.5.3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_dyVae6wqjd from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_@unocss+reset@0.59.2_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4.5_unocss@0.59.2_vite@4.5.3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_Qblh43tAGw from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@1.6.7_@nuxt+devtools@1.1.5_@vue+compiler-core@3.4.21_nuxt@3.8.2_postcss@8.4._evqjftbebffpuffr3bywijgmxu/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import payload_client_iuBG4RzeEy from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_@unocss+reset@0.59.2_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4.5_unocss@0.59.2_vite@4.5.3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/storefront/.nuxt/components.plugin.mjs";
import prefetch_client_393kUjelZX from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_@unocss+reset@0.59.2_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4.5_unocss@0.59.2_vite@4.5.3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_jqwBGp92HZ from "/vercel/path0/node_modules/.pnpm/nuxt-gtag@2.0.5/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_G9WPqDpojy from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.2.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import shopware_plugin_txRT4f0fRY from "/vercel/path0/apps/storefront/.nuxt/runtime/shopware.plugin.mjs";
import plugin_j2LuvnwPZT from "/vercel/path0/node_modules/.pnpm/@storyblok+nuxt@5.7.10/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import chunk_reload_client_XxntyKttDD from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_@unocss+reset@0.59.2_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4.5_unocss@0.59.2_vite@4.5.3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_FA3YcNClWN from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_@unocss+reset@0.59.2_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4.5_unocss@0.59.2_vite@4.5.3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import active_campaign_client_94LcNpk3D3 from "/vercel/path0/apps/storefront/src/plugins/active-campaign.client.ts";
import bird_tracker_client_vQymBaIYyI from "/vercel/path0/apps/storefront/src/plugins/bird-tracker.client.ts";
import clarity_client_G0skVuoIDj from "/vercel/path0/apps/storefront/src/plugins/clarity.client.ts";
import cookiebot_client_2XRNXQo76P from "/vercel/path0/apps/storefront/src/plugins/cookiebot.client.ts";
import pinterest_client_yR7n6LWTH8 from "/vercel/path0/apps/storefront/src/plugins/pinterest.client.ts";
import trengo_client_Slo7HE1gBd from "/vercel/path0/apps/storefront/src/plugins/trengo.client.ts";
export default [
  revive_payload_client_yKCoKkRoyU,
  unhead_Q7Pk59Js85,
  router_dyVae6wqjd,
  _0_siteConfig_Qblh43tAGw,
  payload_client_iuBG4RzeEy,
  components_plugin_KR1HBZs4kY,
  prefetch_client_393kUjelZX,
  plugin_client_jqwBGp92HZ,
  plugin_G9WPqDpojy,
  shopware_plugin_txRT4f0fRY,
  plugin_j2LuvnwPZT,
  chunk_reload_client_XxntyKttDD,
  check_outdated_build_client_FA3YcNClWN,
  active_campaign_client_94LcNpk3D3,
  bird_tracker_client_vQymBaIYyI,
  clarity_client_G0skVuoIDj,
  cookiebot_client_2XRNXQo76P,
  pinterest_client_yR7n6LWTH8,
  trengo_client_Slo7HE1gBd
]