export default defineNuxtPlugin({
	name: "cookiebot",
	parallel: true,
	setup() {
		useHead({
			script: [
				{
					id: "Cookiebot",
					src: "https://consent.cookiebot.com/uc.js",
					"data-cbid": "13368223-e365-424e-a72b-992e4fb25b23",
					"data-blockingmode": "auto",
					type: "text/javascript"
				}
			]
		});
	}
});
