import type { AsyncDataOptions } from "nuxt/app";
import type { KeysOf } from "nuxt/dist/app/composables/asyncData";

export const useCachedAsyncData = <
	ResT,
	DataE = Error,
	DataT = ResT,
	PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
	DefaultT = null
>(
	key: string,
	resolver: () => Promise<ResT>,
	options?: Omit<AsyncDataOptions<ResT, DataT, PickKeys, DefaultT>, "getCachedData">
) => {
	const { data: cachedData } = useNuxtData<ResT>(key);

	return useAsyncData<ResT, DataE, DataT, PickKeys, DefaultT>(key, resolver, {
		...options,
		getCachedData() {
			return cachedData.value as DataT;
		}
	});
};

export const preloadAsyncData = async (key: string, resolver: () => Promise<any>) => {
	const { data: cachedData } = useNuxtData(key);

	if (cachedData.value) return;

	cachedData.value = await resolver();
};
