declare global {
	interface Window {
		pintrk: any;
	}
}

const PINTEREST_SCRIPT_URL = "/tracking-codes/pinterest.js";

export default defineNuxtPlugin({
	name: "pinterest",
	parallel: true,
	setup(nuxtApp) {
		const route = useRoute();
		const { pageVisit } = usePinterestTrackingEvents();

		const { load } = useScriptTag(
			PINTEREST_SCRIPT_URL,
			() => {
				console.timeEnd("pntrst:initialized");
				watch(
					route,
					() => {
						pageVisit();
					},
					{
						immediate: true,
						deep: true
					}
				);
			},
			{
				immediate: false,
				async: true,
				type: "text/javascript",
				manual: true
			}
		);

		nuxtApp.hook("app:mounted", () => {
			console.time("pntrst:initialized");
			load();
		});
	}
});
