import type { SnackbarData as SnackbarConfig } from "@kippie/ui/dist/runtime/components";

type SnackbarInstance = {
	id: string;
} & SnackbarConfig;

export const useSnackbar = createSharedComposable(() => {
	const snackbars = ref<SnackbarInstance[]>([]);

	function open(data: SnackbarConfig): void {
		const randomId = Math.random().toString(36).substr(2, 9);

		snackbars.value = [
			...snackbars.value,
			{
				...data,
				id: randomId
			}
		];

		setTimeout(() => {
			snackbars.value = snackbars.value.filter((snackbar) => snackbar.id !== randomId);
		}, data.time || 5000);
	}

	return {
		open,
		snackbars
	};
});
