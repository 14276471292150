<script setup lang="ts">
import { twMerge } from "tailwind-merge";
import * as pressable from "@zag-js/pressable";
import { normalizeProps, useMachine } from "@zag-js/vue";
import { computed } from "vue";
import { ButtonColor, ButtonType, Justify, ButtonSize } from "../";
import KippieLoader from "./KippieLoader.vue";

const props = withDefaults(
	defineProps<{
		shadow?: boolean;
		block?: boolean;
		size?: ButtonSize;
		large?: boolean;
		pill?: boolean;
		disabled?: boolean;
		fake?: boolean;
		color?: ButtonColor;
		justify?: Justify;
		type?: ButtonType;
		loading?: boolean;
	}>(),
	{
		size: "default",
		shadow: true,
		color: "yellow",
		justify: "center",
		type: "button"
	}
);

const [state, send] = useMachine(
	pressable.machine({
		// generates random id for every component
		id: (Math.random() + 1).toString(36).substring(7),
		disabled: props.disabled
	})
);

const api = computed(() => pressable.connect(state.value, send, normalizeProps));

const buttonColor = computed<string>(() => {
	let color = "bg-yellow text-black-light";

	switch (props.color) {
		case "black":
			color = "bg-black-light text-white";
			return props.shadow ? `${color} shadow-inset-dark` : color;
		case "white":
			color = "bg-white text-black-light border border-brown";
			return props.shadow ? `${color} shadow-inset-light` : color;
		default:
			return props.shadow ? `${color} shadow-inset-light` : color;
	}
});

const buttonSizeClasses: Record<ButtonSize, string> = {
	small: "text-sm px-6 gap-2 h-11.5",
	default: "text-sm sm:text-base gap-3.5 px-6 h-13",
	large: "text-base gap-4 h-16 px-6"
};
</script>

<template>
	<component
		v-bind="api.pressableProps"
		:is="fake ? 'div' : 'button'"
		:disabled="disabled || loading"
		class="font-bold flex items-center disabled:opacity-70 disabled:cursor-not-allowed outline-none hover:scale-[102%] duration-200 ease-in-out sm:whitespace-nowrap rounded overflow-hidden relative"
		:class="
			twMerge([
				buttonColor,
				buttonSizeClasses[size],
				pill && 'py-[10px] px-6 rounded-full',
				block && 'w-full',
				fake && !block && 'w-fit',
				api.isPressed && !disabled && 'translate-y-[2px]',
				(loading || disabled) && fake && 'cursor-not-allowed opacity-70'
			])
		"
		:style="{ justifyContent: justify }"
	>
		<slot name="left" />
		<span v-if="loading" class="absolute left-1/2 -translate-x-1/2"><KippieLoader /></span>
		<span class="my-[2px] relative grow flex overflow-hidden">
			<span
				class="transition-opacity truncate grow"
				:class="{
					'opacity-0': loading,
					'text-center': justify !== 'space-between',
					'text-left': justify === 'space-between'
				}"
			>
				<slot />
			</span>
		</span>
		<slot name="right" />
	</component>
</template>
