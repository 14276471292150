<script setup lang="ts">
import { formatPrice } from "~/helpers/formatters";

const { subtotal } = useShopwareCart();

const amount = computed(() => {
	return 25 - subtotal.value;
});
</script>

<template>
	<div class="flex flex-col gap-4">
		<span class="text-sm font-medium">
			Bestel nog voor {{ formatPrice(amount, true) }} aan heerlijke Kippie producten om je bestelling te laten
			bezorgen.
		</span>
		<KippieProgressBar :value="subtotal" :total="25" variant />
	</div>
</template>
