export default defineNuxtPlugin({
	name: "bird-tracker",
	parallel: true,
	setup () {
		useHead({
			script: [
				{
					id: "BirdTracker",
					src: "https://embeddables.p.mbirdcdn.net/sdk/v0/bird-sdk.js",
					'data-config-url': "https://api.bird.com/workspaces/663c2fab-5839-4768-a598-73b1f3e63f10/applications/197fed34-a498-4ee9-9704-48019f102ab6/signature/2024-06-17T17-12-51_5ba367085c",
				}
			]
		});

		let birdFunctionQueue: Array<{ fn: () => void }> = [];

		const processBirdQueue = () => {
			birdFunctionQueue.forEach(({ fn }) => fn());
			birdFunctionQueue = [];
		};

		// watch if bird is available in window object and if so process the queue
		const watchBird = () => {
			const interval = setInterval(() => {
				if (window.Bird) {
					clearInterval(interval);
					processBirdQueue();
				}
			}, 100);
		};

		watchBird();

		return {
			provide: {
				bird: {
					enqueueOrExecute: (fn: () => void) => {
						if (window.Bird) {
							fn();
						} else {
							birdFunctionQueue.push({ fn });
						}
					},
				}
			}
		}
	}
});
