import {
	getCart,
	addProductToCart,
	removeCartItem,
	changeCartItemQuantity,
	addPromotionCode
} from "@shopware-pwa/api-client";
import type { KippieCart, KippieLocation, LineItem } from "@shopware-pwa/types";

export const useShopwareCartImplementation = (contextToken?: string) => {
	const apiInstance = useShopwareApi(contextToken);
	const { open } = useShoppingCartView();
	const { selectedShippingMethod } = useShopwareSession();

	const {
		state: shoppingCartResponse,
		isLoading,
		isReady
	} = useData(() => getCart(apiInstance) as Promise<KippieCart>, undefined);

	const updateExtension = async (extension: any) => {
		const { data } = await apiInstance.invoke.post<KippieCart>("/store-api/checkout/cart", extension);
		shoppingCartResponse.value = data;
	};

	const refreshCart = async () => {
		shoppingCartResponse.value = (await getCart(apiInstance)) as KippieCart;
	};

	async function addProduct(params: { id: string; quantity?: number }) {
		const addToCartResult = await addProductToCart(params.id, params.quantity || 1, apiInstance);
		shoppingCartResponse.value = addToCartResult as KippieCart;

		if (Object.keys(errors.value).length) {
			open();
		}
	}

	async function removeItem(lineItem: LineItem) {
		const removeCartItemResult = await removeCartItem(lineItem.id, apiInstance);
		shoppingCartResponse.value = removeCartItemResult as KippieCart;
	}

	async function changeProductQuantity(params: { id: string; quantity: number }) {
		const changeCartItemResult = await changeCartItemQuantity(params.id, params.quantity, apiInstance);
		shoppingCartResponse.value = changeCartItemResult as KippieCart;
	}

	async function submitPromotionCode(promotionCode: string) {
		const addPromotionCodeResult = await addPromotionCode(promotionCode, apiInstance);
		shoppingCartResponse.value = addPromotionCodeResult as KippieCart;
	}

	const hasCrititalErrors = computed(() =>
		Object.values(shoppingCartResponse.value?.errors || {}).some((error) => error.level === 20)
	);
	const isEmpty = computed(() => shoppingCartResponse.value?.lineItems.length === 0);
	const cartItems = computed(() => shoppingCartResponse.value?.lineItems || []);
	const itemsCount = computed(() =>
		shoppingCartResponse.value?.lineItems.reduce((count, item) => {
			return count + item.quantity;
		}, 0)
	);
	const totalPrice = computed(() => {
		const cartPrice =
			shoppingCartResponse.value &&
			shoppingCartResponse.value.price &&
			shoppingCartResponse.value.price.totalPrice;
		return cartPrice || 0;
	});
	const shippingTotal = computed(() => shoppingCartResponse.value?.deliveries?.[0]?.shippingCosts?.totalPrice || 0);
	const subtotal = computed(() => shoppingCartResponse.value?.price?.positionPrice || 0);
	const isVirtualCart = computed(
		() =>
			!isEmpty &&
			cartItems.value
				?.filter((element) => element.type !== "promotion")
				.every((item) => item.states.includes("is-download"))
	);
	const timeslot = computed(() => shoppingCartResponse.value?.extensions?.deliveryTimeSlot);

	const selectedPickupLocation = computed<KippieLocation | undefined>(
		() => shoppingCartResponse.value?.extensions?.pickupLocationData as KippieLocation
	);
	const errors = computed(() => shoppingCartResponse.value?.errors || {});

	watch(
		[selectedShippingMethod, selectedPickupLocation],
		async ([newMethod, newLocation], [oldMethod, oldLocation]) => {
			if (
				(!!oldMethod && newMethod?.id !== oldMethod.id) ||
				(!!oldLocation && newLocation?.id !== oldLocation.id)
			) {
				updateExtension({
					deliveryTimeSlot: null
				});
			}
		}
	);

	return {
		isLoading,
		isReady,
		cart: shoppingCartResponse,
		refreshCart,
		cartItems,
		itemsCount,
		hasCrititalErrors,
		isEmpty,
		totalPrice,
		subtotal,
		shippingTotal,
		isVirtualCart,
		selectedPickupLocation,
		errors,
		updateExtension,
		addProduct,
		removeItem,
		changeProductQuantity,
		addPromotionCode: submitPromotionCode,
		timeslot
	};
};

export const useShopwareCart = createSharedComposable(useShopwareCartImplementation);
