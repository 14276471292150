import {
	getSessionContext,
	setCurrentShippingMethod,
	setCurrentPaymentMethod,
	setCurrentCurrency,
	setCurrentLanguage,
	setCurrentCountry,
	setCurrentShippingAddress,
	setCurrentBillingAddress
} from "@shopware-pwa/api-client";
import type { Currency, PaymentMethod, CustomerAddress } from "@shopware-pwa/types";
import { SHIPPING_METHODS_LIST } from "~/lib/constants";

export const useShopwareSessionImplementation = (contextToken?: string) => {
	const apiInstance = useShopwareApi(contextToken);

	const { state: sessionContext, isLoading, isReady } = useData(() => getSessionContext(apiInstance), undefined);

	const refreshSessionContext = async () => {
		sessionContext.value = await getSessionContext(apiInstance);
	};

	const selectedShippingMethod = computed(() => {
		if (!sessionContext.value) return;

		const shippingMethod = SHIPPING_METHODS_LIST.find(
			(method) => method.shopwareId === sessionContext.value!.shippingMethod?.id
		);
		if (!shippingMethod) return;

		return {
			...sessionContext.value.shippingMethod,
			...shippingMethod
		};
	});

	const setShippingMethod = async (shippingMethodId: string) => {
		await setCurrentShippingMethod(shippingMethodId, apiInstance);
		await refreshSessionContext();
	};

	const selectedPaymentMethod = computed<PaymentMethod | undefined>(() => sessionContext.value?.paymentMethod);
	const setPaymentMethod = async (paymentMethodId: string) => {
		await setCurrentPaymentMethod(paymentMethodId, apiInstance);
		await refreshSessionContext();
	};

	const currency = computed<Currency | undefined>(() => sessionContext.value?.currency);
	const setCurrency = async (currencyId: string) => {
		await setCurrentCurrency(currencyId, apiInstance);
		await refreshSessionContext();
	};

	const setLanguage = async (languageId: string) => {
		await setCurrentLanguage(languageId, apiInstance);
		await refreshSessionContext();
	};
	const setCountry = async (countryId: string) => {
		await setCurrentCountry(countryId, apiInstance);
		await refreshSessionContext();
	};

	const activeShippingAddress = computed<CustomerAddress | undefined>(
		() => sessionContext.value?.shippingLocation?.address
	);
	const setActiveShippingAddress = async (addressId: string, skipRefresh?: boolean) => {
		await setCurrentShippingAddress(addressId, apiInstance);
		if (skipRefresh) return;
		refreshSessionContext();
	};

	const activeBillingAddress = computed(() => sessionContext.value?.customer?.defaultBillingAddress);
	const setActiveBillingAddress = async (addressId: string, skipRefresh?: boolean) => {
		await setCurrentBillingAddress(addressId, apiInstance);
		if (skipRefresh) return;
		refreshSessionContext();
	};

	const setActiveAddress = async (addressId: string) => {
		await apiInstance.invoke.patch("/store-api/context", {
			billingAddressId: addressId,
			shippingAddressId: addressId
		});
		refreshSessionContext();
	};

	const countryId = computed(() => sessionContext.value?.salesChannel?.countryId);
	const languageId = computed(() => sessionContext.value?.salesChannel?.languageId);
	const languageIdChain = computed(() => sessionContext.value?.context?.languageIdChain?.[0]);
	const taxState = computed(() => sessionContext.value?.context?.taxState);
	const userFromContext = computed({
		get: () => sessionContext.value?.customer,
		set: (value) => {
			if (!sessionContext.value) return;

			sessionContext.value = {
				...sessionContext.value,
				customer: value
			};
		}
	});

	return {
		sessionContext,
		refreshSessionContext,
		isLoading,
		isReady,
		selectedShippingMethod,
		setShippingMethod,
		selectedPaymentMethod,
		setPaymentMethod,
		currency,
		setCurrency,
		setLanguage,
		setCountry,
		activeShippingAddress,
		setActiveShippingAddress,
		activeBillingAddress,
		setActiveBillingAddress,
		setActiveAddress,
		countryId,
		languageId,
		languageIdChain,
		taxState,
		userFromContext
	};
};

export const useShopwareSession = createSharedComposable(useShopwareSessionImplementation);
