type PinterestEvent =
	| "checkout"
	| "addtocart"
	| "pagevisit"
	| "signup"
	| "watchvideo"
	| "lead"
	| "search"
	| "viewcategory"
	| "custom";

type PinterestEventData = {
	event_id: string;
	value?: number;
	order_quantity?: number;
	currency?: "USD" | "EUR";
	order_id?: string;
	line_items?: PinterestLineItem[];
	promo_code?: string;
	property?: string;
	search_query?: string;
	video_title?: string;
	lead_type?: string;
};

type PinterestLineItem = {
	product_name?: string;
	product_id?: string;
	product_category?: string;
	product_variant_id?: string;
	product_variant?: string;
	product_price?: number;
	product_quantity?: number;
	product_brand?: string;
};

export const usePinterestTracking = () => {
	if (!process.client) {
		return {
			send: () => {}
		};
	}

	const send = async (event: PinterestEvent, data: Partial<PinterestEventData>) => {
		// Send data to Pinterest
		window.pintrk("track", event, data);
	};

	return {
		send
	};
};
