interface BirdTracker {
	contact: {
		identify: (props: { strategy: string, identifier: { key: string, value: string } }) => void
		refreshAnonymousId: Promise<void>
		sendVerificationRequest: (props: { strategy: string, identifier: { key: string, value: string } }) => void
		updateCurrent: (attributes: any) => void
	},
	tracker: {
		tracker: {
			pageViewed: (props?: TrackerPageViewedProperties) => void
			formSubmitted: (props?: TrackerFormSubmittedProperties) => void
			formViewed: (props?: TrackerFormViewedProperties) => void
		}
		ecommerce: {
			cartUpdated: (props?: EcommerceCartUpdatedProperties) => void
			cartViewed: (props?: EcommerceCartViewedProperties) => void
			checkoutAbandoned: (props?: EcommerceCheckoutAbandonedProperties) => void
			checkoutUpdated: (props?: EcommerceCheckoutUpdatedProperties) => void
			collectionViewed: (props?: EcommerceCollectionViewedProperties) => void
			orderConfirmed: (props?: EcommerceOrderConfirmedProperties) => void
			orderCanceled: (props?: EcommerceOrderCanceledProperties) => void
			orderFulfilled: (props?: EcommerceOrderFulfilledProperties) => void
			productViewed: (props?: EcommerceProductViewedProperties) => void
		},
		web: {
			pageViewed: (props?: TrackerPageViewedProperties) => void
			formSubmitted: (props?: TrackerFormSubmittedProperties) => void
			formViewed: (props?: TrackerFormViewedProperties) => void
			searchSubmitted: (props?: WebSearchSubmittedProperties) => void
		}
	}
}

declare global {
	interface Window {
		Bird: BirdTracker
	}
}

type WebSearchSubmittedProperties = {
	path?: string;
	referrer?: string;
	search?: string;
	title?: string;
	url?: string;
}

type TrackerFormViewedProperties = {
	anonymous_id?: string;
	app_id?: string;
	browser_useragent?: string;
	contact_id?: string;
	derived_ts?: string;
	event_fingerprint?: string;
	event_id?: string;
	event_name?: string;
	form_project_id?: string;
	form_step_id?: string;
	ip_address?: string;
	os_timezone?: string;
	override_ts?: string;
	page_title?: string;
	platform?: string;
	referrer?: string;
	screen_height?: number;
	screen_width?: number;
	session_id?: string;
	session_index?: string;
	trk_created_ts?: string;
	trk_namespace?: string;
	trk_sent_ts?: string;
	trk_version?: string;
	url?: string;
}

type TrackerFormSubmittedProperties = TrackerFormViewedProperties

type TrackerPageViewedProperties = {
	anonymous_id?: string;
	app_id?: string;
	browser_useragent?: string;
	contact_id?: string;
	derived_ts?: string;
	event_fingerprint?: string;
	event_id?: string;
	event_name?: string;
	ip_address?: string;
	os_timezone?: string;
	override_ts?: string;
	page_title?: string;
	platform?: string;
	referrer?: string;
	screen_height?: number;
	screen_width?: number;
	session_id?: string;
	session_index?: string;
	trk_created_ts?: string;
	trk_namespace?: string;
	trk_sent_ts?: string;
	trk_version?: string;
	url?: string;
}

type EcommerceCartUpdatedProperties = {
	cart_id?: string
	checkout_url?: string
	currency?: string
	customer_email?: string
	customer_phone?: string
	product_ids?: string[]
	product_names?: string[]
	product_skus?: string[]
	total_price?: number
	total_quantity?: number
}

type EcommerceCartViewedProperties = EcommerceCartUpdatedProperties

type EcommerceCheckoutAbandonedProperties = {
	checkout_id?: string;
	checkout_url?: string;
	currency?: string;
	customer_email?: string;
	customer_phone?: string;
	product_categories?: string[];
	product_ids?: string[];
	product_names?: string[];
	product_skus?: string[];
	total_price?: number;
}

type EcommerceCheckoutUpdatedProperties = EcommerceCheckoutAbandonedProperties

type EcommerceCollectionViewedProperties = {
	[key: string]: any
}

type EcommerceOrderCanceledProperties = {
	currency?: string;
	customer_email?: string;
	customer_phone?: string;
	financial_status?: string;
	order_canceled_at?: string | Date;
	order_created_at?: string | Date;
	order_id?: string;
	order_number?: string;
	order_updated_at?: string;
	order_url?: string;
	product_categories?: string[];
	product_ids?: string[];
	product_names?: string[];
	product_skus?: string[];
	reason?: string;
	total_price?: number;
}

type EcommerceOrderConfirmedProperties = {
	currency?: string;
	customer_email?: string;
	customer_phone?: string;
	financial_status?: string;
	order_created_at?: string | Date;
	order_id?: string;
	order_number?: string;
	order_updated_at?: string;
	order_url?: string;
	product_categories?: string[];
	product_ids?: string[];
	product_names?: string[];
	product_skus?: string[];
	total_price?: number;
}

type EcommerceOrderFulfilledProperties = {
	currency?: string;
	customer_email?: string;
	customer_phone?: string;
	financial_status?: string;
	fulfillment_status?: string;
	order_closed_at?: string | Date;
	order_created_at?: string | Date;
	order_id?: string;
	order_number?: string;
	order_processed_at?: string;
	order_updated_at?: string;
	order_url?: string;
	product_categories?: string[];
	product_ids?: string[];
	product_names?: string[];
	product_skus?: string[];
	total_price?: number;
}

type EcommerceProductViewedProperties = {
	price?: number;
	product_image_url?: string;
	product_url?: string;
	product_name?: string;
}




export const useBirdWatchers = () => {
	const { $bird } = useNuxtApp()
	const route = useRoute()
	const { user } = useShopwareUser()
	const { cart, totalPrice } = useShopwareCart()
	const { onCartUpdated, onCartViewed, pageView, identify } = useBird()
	const { isOpen: shoppingCartIsOpen } = useShoppingCartView();

	// pageview watcher
	watch(() => route.path, () => {
		pageView()
	}, {
		immediate: false
	})
	onMounted(() => {
		if (user.value?.email) {
			$bird.enqueueOrExecute(() => {
				identify('emailaddress', user.value?.email ?? '')
			})

			$fetch('/api/bird/sync-contact', {
				method: 'POST',
				body: {
					...user.value
				}
			})
		}
	})

	watch(user, () => {
		if (user.value?.email) {
			$bird.enqueueOrExecute(() => {
				identify('emailaddress', user.value?.email ?? '')
			})

			$fetch('/api/bird/sync-contact', {
				method: 'POST',
				body: {
					...user.value
				}
			})
		}
	})

	// updated cart watcher
	watch(cart, () => {
		onCartUpdated({
			cart_id: cart.value?.token,
			checkout_url: 'https://kippie.nl/bestellen/overzicht',
			currency: 'EUR',
			customer_email: user.value?.email ?? undefined,
			customer_phone: user.value?.activeBillingAddress?.phoneNumber ?? undefined,
			product_ids: cart.value?.lineItems.map(item => item.referencedId as string) ?? [],
			product_names: cart.value?.lineItems.map(item => item.label as string) ?? [],
			product_skus: cart.value?.lineItems.map(item => (item.payload as any)['productNumber'] as string) ?? [],
			total_price: totalPrice.value,
			total_quantity: cart.value?.lineItems.reduce((acc, item) => acc + item.quantity, 0)
		})
	})


	watch(shoppingCartIsOpen, () => {
		if (shoppingCartIsOpen.value) {
			onCartViewed({
				cart_id: cart.value?.token,
				checkout_url: 'https://kippie.nl/bestellen/overzicht',
				currency: 'EUR',
				customer_email: user.value?.email ?? undefined,
				customer_phone: user.value?.activeBillingAddress?.phoneNumber ?? undefined,
				product_ids: cart.value?.lineItems.map(item => item.referencedId as string) ?? [],
				product_names: cart.value?.lineItems.map(item => item.label as string) ?? [],
				product_skus: cart.value?.lineItems.map(item => (item.payload as any)['productNumber'] as string) ?? [],
				total_price: totalPrice.value,
				total_quantity: cart.value?.lineItems.reduce((acc, item) => acc + item.quantity, 0)
			})
		}
	})
}


export const useBird = () => {
	const { $bird } = useNuxtApp()

	const identify = (identifierKey: 'emailaddress' | 'phonenumber' = 'emailaddress', value: string) => {
		$bird.enqueueOrExecute(() =>
			window?.Bird?.contact?.identify({
				strategy: 'Visitor',
				identifier: {
					key: identifierKey,
					value: value
				}
			}))
	}

	const pageView = () => {
		$bird.enqueueOrExecute(() => window.Bird.tracker.tracker.pageViewed())
	}

	const onFormSubmitted = (props?: TrackerFormSubmittedProperties) => {
		$bird.enqueueOrExecute(() => window.Bird.tracker.tracker.formSubmitted(props))
	}

	const onFormViewed = (props?: TrackerFormViewedProperties) => {
		$bird.enqueueOrExecute(() => window.Bird.tracker.tracker.formViewed(props))
	}

	const onSearchSubmitted = (props?: WebSearchSubmittedProperties) => {
		$bird.enqueueOrExecute(() => window.Bird.tracker.web.searchSubmitted(props))
	}

	const onCartUpdated = (props?: EcommerceCartUpdatedProperties) => {
		$bird.enqueueOrExecute(() => window.Bird.tracker.ecommerce.cartUpdated(props))
	}

	const onCartViewed = (props?: EcommerceCartViewedProperties) => {
		$bird.enqueueOrExecute(() => window.Bird.tracker.ecommerce.cartViewed(props))
	}

	const onCheckoutAbandoned = (props?: EcommerceCheckoutAbandonedProperties) => {
		$bird.enqueueOrExecute(() => window.Bird.tracker.ecommerce.checkoutAbandoned(props))
	}

	const onCheckoutUpdated = (props?: EcommerceCheckoutUpdatedProperties) => {
		$bird.enqueueOrExecute(() => window.Bird.tracker.ecommerce.checkoutUpdated(props))
	}

	const onCollectionViewed = (props?: EcommerceCollectionViewedProperties) => {
		$bird.enqueueOrExecute(() => window.Bird.tracker.ecommerce.collectionViewed(props))
	}

	const onOrderConfirmed = (props?: EcommerceOrderConfirmedProperties) => {
		$bird.enqueueOrExecute(() => window.Bird.tracker.ecommerce.orderConfirmed(props))
	}

	const onOrderCanceled = (props?: EcommerceOrderCanceledProperties) => {
		$bird.enqueueOrExecute(() => window.Bird.tracker.ecommerce.orderCanceled(props))
	}

	const onOrderFulfilled = (props?: EcommerceOrderFulfilledProperties) => {
		$bird.enqueueOrExecute(() => window.Bird.tracker.ecommerce.orderFulfilled(props))
	}

	const onProductViewed = (props?: EcommerceProductViewedProperties) => {
		$bird.enqueueOrExecute(() => window.Bird.tracker.ecommerce.productViewed(props))
	}

	return { identify, pageView, onFormSubmitted, onFormViewed, onSearchSubmitted, onCartUpdated, onCartViewed, onCheckoutAbandoned, onCheckoutUpdated, onCollectionViewed, onOrderConfirmed, onOrderCanceled, onOrderFulfilled, onProductViewed }
}