const CLARITY_SCRIPT_URL = "/tracking-codes/clarity.js";

export default defineNuxtPlugin({
	name: "ms-clarity",
	parallel: true,
	setup(nuxtApp) {
		const { load } = useScriptTag(
			CLARITY_SCRIPT_URL,
			() => {
				console.timeEnd("ms-clarity:initialized");
			},
			{
				immediate: false,
				async: true,
				type: "text/javascript",
				manual: true
			}
		);

		nuxtApp.hook("app:mounted", () => {
			console.time("ms-clarity:initialized");
			load();
		});
	}
});
