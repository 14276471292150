type DrawerContentComponent = {
	new (...args: unknown[]): {
		$props: {
			[key: string]: unknown;
			id?: string;
			closeDrawer: () => void;
		};
		onDrawerClose?: () => void;
	};
};

type Drawer<T extends DrawerContentComponent> = {
	id: string;
	component: T;
	props: DrawerProps<T>;
};

type DrawerProps<T extends DrawerContentComponent> = InstanceType<T>["$props"] & { drawerTitle?: string };

export const useDrawer = createSharedComposable(() => {
	const drawers = ref<Drawer<DrawerContentComponent>[]>([]);

	const activeDrawerComponent = ref<InstanceType<DrawerContentComponent>>();

	const showDrawer = <T extends DrawerContentComponent>(
		component: T,
		props?: Omit<DrawerProps<T>, "closeDrawer">
	) => {
		const id = (props?.id as string) || (Math.random() + 1).toString(36).substring(7);

		if (drawers.value.some((m) => m.id === id)) {
			return new Promise<void>((resolve) => resolve());
		}

		return new Promise<void>((resolve) => {
			const newDrawer = {
				id,
				component,
				props: {
					...props,
					closeDrawer: () => {
						drawers.value = drawers.value.filter((drawer) => drawer.id !== id);
						resolve();
					}
				}
			};

			drawers.value.push(markRaw(newDrawer));
		});
	};

	return {
		drawers,
		showDrawer,
		activeDrawerComponent
	};
});
